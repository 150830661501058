footer.ant-layout-footer{
    background-color: var(--e-color-blue);
    color: #fff;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding: 20px 50px 20px;
}
.footer-menu.ant-menu-horizontal{
    justify-content: center;
    background-color: transparent;
    color: #fff;
}
.ant-menu-horizontal.footer-menu >.ant-menu-item a,
.ant-menu-horizontal.footer-btm-menu >.ant-menu-item a{
    color: #fff;
}
.ant-menu-horizontal.footer-menu >.ant-menu-item:hover a,
.ant-menu-horizontal.footer-btm-menu >.ant-menu-item:hover a{
    color: var(--e-color-orange);
}
.footer-menu{
    background-color: transparent;
    border-right: none;
}
.footer-menu li.ant-menu-item{
    padding: 0px 45px !important;
}
.footer-menu li.ant-menu-item:active{
    background-color: transparent;
}
.footer-btm-menu{
    background: none;
    margin-right: -16px;
}
.footer-btm-menu li.ant-menu-item{
    line-height: 22px;
}
.anticon{
    font-size: 26px;
    margin: 0px 8px;
}
.ant-row.footerBottom{
    padding-top: 20px;
    margin-top: 20px !important;
}
.footerBottom::before{
    position: absolute;
    content: '';
    border-top: 1px solid rgba(255, 255, 255, .25);
    width: 100%;
    left: 0px;
    margin-top: -20px;
}
.ant-layout-footer h6{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins-Regular';
    margin-bottom: 20px;
}
.socialicon{
    border-radius: 50px;
    border:2px solid #fff;
    height: 44px;
    width: 46px;
    min-width: 46px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.socialicon:hover{
    background-color: var(--e-color-orange);
    border-color: var(--e-color-orange);
}
.ant-layout-footer ul li a:hover{
    color: var(--e-color-orange);
}
footer .ant-menu-horizontal{
    border-bottom: none;
}
.footerBottom .ant-menu-horizontal>.ant-menu-item:after{
    content: none;
}
/* .fb{
    background-color: #374CA3;
}
.tweet{
    background-color: #1DA1F2;
}
.lin{
    background-color: #0C62BF;
} */
.callToAction{
    color: #fff;
    margin-right: 40px;
}
.callToAction:hover{
    color: var(--e-color-orange);
}
.emailID{
    margin: 20px 0px;
}
@media screen and (max-width: 767px){
footer div > div:nth-child(2){
    text-align: left;
}
.justify-content-end{
    justify-content: center;
}
footer div > div:nth-child(3){
    margin: 0px 0px 0px;
}
.copyright{
    margin-bottom: 30px;
}
footer.ant-layout-footer{
    padding:25px 20px;
}
.ant-row.footerBottom{
    margin-top: 30px !important;
}
.socialicon{
    margin: 0px;
}
footer .ant-menu-horizontal.footer-menu{
    justify-content: left;
}
footer .ant-menu-horizontal.footer-btm-menu{
    margin: 0px;
    justify-content: space-evenly;
}
.footer-menu li.ant-menu-item{
    padding: 0px 15px !important;
}
}